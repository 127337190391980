if (window.location.host.includes('localhost')) {
	window.devEnvironment = true;
}

const drawChat = require('./chatIntegration.js');

let disk = null, disk_canvas = null, dsk_ctx = null;

const query_vars = {};
const query_parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
	query_vars[key] = value;
});

function easeOutQuad(t) { return t * (2 - t) }

const piMult = Math.PI / 180;

const openTimeout = 5000;
const closeTimeout = 28000;

const init = () => {
	disk = document.querySelector('.bs2-disk');
	disk_canvas = document.querySelector('.canvas-disk');
	disk_canvas.width = disk_canvas.offsetWidth * 2;
	disk_canvas.height = disk_canvas.offsetHeight * 2;
	dsk_ctx = disk_canvas.getContext('2d');

	draw();

	if (query_vars.pee === "true") {
		const peeElements = document.querySelectorAll('.bs2, .bs2-front');
		for (let index = 0; index < peeElements.length; index++) {
			const element = peeElements[index];
			element.classList.add('text-pee');
		}
	}

}

window.addEventListener('click', () => {
	if (!document.body.classList.contains('open')) {
		open();
	} else {
		close();
	}

});

let chatIntegration = false;
let recentChatIntegration = false;
let firstFrame = true;

const open = () => {
	dsk_ctx.clearRect(0, 0, disk_canvas.width, disk_canvas.height);

	for (const key in disk_canvas.dataset) {
		if (disk_canvas.dataset.hasOwnProperty(key)) {
			disk_canvas.dataset[key] = false;
		}
	}

	lastToggleTime = Date.now();
	lastToggleAction = 'open';

	firstFrame = true;

	if (recentChatIntegration) {
		recentChatIntegration = false;
		chatIntegration = false;
		document.body.classList.remove('encompass-disk');
		imageIndex++;
		if (imageIndex >= games.length) imageIndex = 0;
		image.src = games[imageIndex];
	} else {
		recentChatIntegration = true;
		document.body.classList.add('encompass-disk');
		chatIntegration = Math.random();
	}

	rotation = 0;
	rotationVelocity = 0;
	document.body.classList.add('open');
}
const close = () => {
	lastToggleTime = Date.now();
	lastToggleAction = 'close';
	document.body.classList.remove('open');
}

const r = (max) => {
	return Math.random() * max;
}


import ragnarok from '../img/games/ragnarok.png';
import dunggeon from '../img/games/dunggeon.png';
import Weebtcher from '../img/games/ppmon7908.png';
import smegwatch2 from '../img/games/bubblarzsmegwatch2.png';
import StupidSexyGeralt from '../img/games/StupidSexyGeralt.png';
import theWeebfather from '../img/games/theWeebfather.png';
import angrybaldeater3 from '../img/games/angrybaldeater3.png';
import cuteDatingSim from '../img/games/cuteDatingSim.png';
import Impossible68Games from '../img/games/BillSimmons.png';
import Mooncraft from '../img/games/toona.png';
import EelFishingSimulator from '../img/games/kriFishing.png';
import hilohuNekopara from '../img/games/hilohuNekopara.png';
import axleBroBorf from '../img/games/axleBroBorf.png';
import abbaHarvestMoon from '../img/games/abbaHarvestMoon.png';
import hilohuCocksplitters from '../img/games/hilohuCocksplitters.png';
import kikobriGodhandpoop from '../img/games/kikobriGodhandpoop.png';
import SeaAssassinForgottenOnes from '../img/games/SeaAssassinForgottenOnes.png';
import dt2k16BaldMan from '../img/games/dt2k16BaldMan.png';
import dt2k16CokeGaming from '../img/games/dt2k16CokeGaming.png';
import dt2k16Deathstranding from '../img/games/dt2k16Deathstranding.png';
import ascalonNightfart from '../img/games/ascalonNightfart.png';
import hilohuGTARP from '../img/games/hilohuGTARP.png';
import dt2k16SpeedRat from '../img/games/dt2k16SpeedRat.png';
import dt2k16RootinTootin from '../img/games/dt2k16RootinTootin.png';
import dt2k16Spaggehtti from '../img/games/dt2k16Spaggehtti.png';
import dt2k16GTARP from '../img/games/dt2k16GTARP.png';
import numriiDragonBald from '../img/games/numriiDragonBald.png';
import numriiModKombat from '../img/games/numriiModKombat.png';


const gamePool = [numriiModKombat, numriiDragonBald, dt2k16SpeedRat, dt2k16RootinTootin, dt2k16Spaggehtti, dt2k16GTARP, hilohuGTARP, ascalonNightfart, dt2k16BaldMan, dt2k16CokeGaming, dt2k16Deathstranding, hilohuCocksplitters, SeaAssassinForgottenOnes, kikobriGodhandpoop, axleBroBorf, abbaHarvestMoon, angrybaldeater3, EelFishingSimulator, hilohuNekopara, Impossible68Games, Mooncraft, ragnarok, dunggeon, cuteDatingSim, Weebtcher, smegwatch2, StupidSexyGeralt, theWeebfather];
const games = new Array();

while (gamePool.length > 0) {
	const element = gamePool.splice(Math.floor(Math.random() * gamePool.length), 1)[0];
	games.push(element);
}

const gameImages = new Array(games.length);
for (let index = 0; index < games.length; index++) {
	const element = games[index];
	gameImages[index] = new Image();
	setTimeout(()=>{
		gameImages[index].src = games[index];
	}, index*500);
}

let imageIndex = -1;
const image = new Image();
image.src = games[0];

let rotation = 0;
let rotationVelocity = 0;
const rotationFriction = 0.008;

let lastTime = Date.now();
let lastToggleTime = Date.now();
let lastToggleAction = 'close';
let drawDumbImage = true;
const draw = () => {
	window.requestAnimationFrame(draw);

	switch (lastToggleAction) {
		case 'close':
			if (Date.now() - lastToggleTime > openTimeout) {
				open();
			}
			break;
		case 'open':
			if (Date.now() - lastToggleTime > closeTimeout) {
				close();
			}
			break;
	}

	const timeDiff = (Date.now() - lastTime) / 17;
	lastTime = Date.now();

	rotation = easeOutQuad(rotationVelocity) * 360 - 90;

	while (rotation > 360) rotation -= 360;
	while (rotation < 0) rotation += 360;

	disk_canvas.style.transform = "rotate(" + rotation + "deg)";

	if (rotationVelocity < 1) {
		rotationVelocity += rotationFriction;
	}

	//dsk_ctx.clearRect(0, 0, disk_canvas.width, disk_canvas.height);
    /*dsk_ctx.save();
    dsk_ctx.translate(disk_canvas.width/2, disk_canvas.width/2);
    dsk_ctx.rotate(piMult);
    dsk_ctx.drawImage(disk_canvas, -disk_canvas.width/2, -disk_canvas.width/2);
    dsk_ctx.restore();

    dsk_ctx.fillStyle = `rgb(${r(256)}, ${r(256)}, ${r(256)})`;
    dsk_ctx.fillRect(r(disk_canvas.width),r(disk_canvas.width),5,5);*/
	try {
		if (!chatIntegration) {
            /*dsk_ctx.save();
            dsk_ctx.translate(disk_canvas.width/2, disk_canvas.width/2);
			dsk_ctx.rotate(piMult*rotation);*/
			
			if (firstFrame) {
				drawDumbImage = true;
			}
			if (drawDumbImage) {
				if (image.complete) drawDumbImage = false;
				dsk_ctx.drawImage(image, 0, 0, disk_canvas.width, disk_canvas.width);
			}
			//dsk_ctx.restore();
		} else {
			drawChat(dsk_ctx, disk_canvas, chatIntegration, timeDiff, firstFrame);
		}
		firstFrame = false;
	} catch (e) {
		console.debug(e)
	}

}

window.addEventListener('load', init);