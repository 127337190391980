const tmi = require('tmi.js');

const __emoteAPI = "https://gif-emotes.opl.io";

let channels = ['moonmoon'];
const query_vars = {};
const query_parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
	query_vars[key] = value;
});
if (query_vars.channels) {
	channels = query_vars.channels.split(',');
}

const client = new tmi.Client({
	options: { debug: false },
	connection: {
		reconnect: true,
		secure: true
	},
	channels: channels
});

window.addEventListener('load', () => {
	client.connect();
})

const emotes = {};

const drawEmote = (url) => {
	if (!emotes[url]) {
		emotes[url] = {
			count: 0,
			image: new Image(),
			gif: false,
		}
		emotes[url].image.src = url;

		if (url.includes('betterttv')) {
			const split = url.split('/');
			const emoteID = split[split.length-2];

			fetch(`${__emoteAPI}/gif/${emoteID}`)
			.then(response => response.json())
			.then(data => {
				if (data && data.count > 0) {
					emotes[url].gif = true;
					emotes[url].frame = 0;
					emotes[url].frameCount = data.count;
					emotes[url].gifArray = new Array(data.count);
					for (let index = 0; index < emotes[url].gifArray.length; index++) {
						emotes[url].gifArray[index] = new Image();
						emotes[url].gifArray[index].src = `${__emoteAPI}/static/${emoteID}/${index}.png`;
					}
					setInterval(()=>{
						emotes[url].frame++;
						if (emotes[url].frame > emotes[url].frameCount) {
							emotes[url].frame = 0;
						}
					}, 30);
				}
			})
		}
	}
	///emotes[url].count += 1;
	return url;
}

function getFrame (url, offset = 0) {
	if (!emotes[url].gif) {
		return emotes[url].image;
	} else {
		let index = emotes[url].frame + Math.floor(offset * emotes[url].frameCount);
		while (index >= emotes[url].frameCount) index -= emotes[url].frameCount;
		return emotes[url].gifArray[index];
	}
}

function dehash(channel) {
	return channel.replace(/^#/, '');
}

function capitalize(n) {
	return n[0].toUpperCase() + n.substr(1);
}

const checkBTTV = (string) => {
	if (bttvEmotes[string] && !emotes[string]) {
		//emotes[string] = true;
		return drawEmote('https://cdn.betterttv.net/emote/' + bttvEmotes[string] + '/3x');
	}
	return false;
}

function formatEmotes(text, emotes) {
	const output = new Array();
	const stringArr = text.split(' ');
	let counter = 0;
	const emoteCache = {};
	for (let index = 0; index < stringArr.length; index++) {
		const string = stringArr[index];
		if (!emoteCache[string]) {
			if (emotes !== null) {
				for (let i in emotes) {
					const arr = emotes[i][0].split('-');
					if (parseInt(arr[0]) === counter) {
						output.push(drawEmote('http://static-cdn.jtvnw.net/emoticons/v1/' + i + '/3.0'));
						emoteCache[string] = true;
					}
					break;
				}
			}
			const bttvOutput = checkBTTV(string);
			if (bttvOutput !== false) {
				output.push(bttvOutput);
				emoteCache[string] = true;
			}
		}
		counter+=string.length + 1;
	}

	if (output.length > 0) {
		toiletEmotesArray.push({
			progress: 0,
			random: Math.random(),
			r2: Math.random(),
			r3: Math.random(),
			frameOffset: Math.random(),
			emotes: output,
		})
	}
}


function handleChat(channel, user, message, self) {
	if (Date.now() - lastFrame < 250) {
		formatEmotes(message, user.emotes);
	}
}

const bttvEmotes = {};
fetch('https://api.betterttv.net/2/channels/' + dehash(channels[0]))
	.then(json => json.json())
	.then(data => {
		if (!data.status || data.status != 404) {
			for (let index = 0; index < data.emotes.length; index++) {
				const emote = data.emotes[index];
				bttvEmotes[emote.code] = emote.id;
			}
		}
	})

fetch('https://api.betterttv.net/3/cached/emotes/global')
	.then(json => json.json())
	.then(data => {
		if (data && data.length > 0) {
			for (let index = 0; index < data.length; index++) {
				const emote = data[index];
				bttvEmotes[emote.code] = emote.id;
			}
		}
	})


client.addListener('message', handleChat);

let spinningEmotes_rotation = 0;
const spem_center = 150;
const spinningEmotes = (ctx, canvas, r, t, first) => {
	ctx.clearRect(0, 0, canvas.width, canvas.height)

	for (let index = toiletEmotesArray.length - 1; index >= 0; index--) {
		const e = toiletEmotesArray[index];

		ctx.save();
		ctx.translate(canvas.width / 2, canvas.height / 2);
		ctx.rotate(Math.PI * 2 * e.random + Math.PI + spinningEmotes_rotation);
		
		const pos = spem_center+(
			(canvas.width/2.75-spem_center)*
			(Math.floor(
				(1-e.r2*e.r2)
			*3)/3)
		);
		ctx.translate(pos, pos);

		ctx.rotate(Math.PI*-1.25);

		ctx.globalAlpha = Math.min(1, e.progress >= 0.5 ? (1-e.progress)*7 : e.progress*7);


		const emoteScale = e.progress;
		/*{
			progress: 0,
			random: Math.random(),
			r2: Math.random(),
			emotes: output,
		}*/
		let offset = 0;
		const multiplier = 1;
		for (let i = 0; i < e.emotes.length; i++) {
			const emoteImageUrl = e.emotes[i];
			
			const image = getFrame(emoteImageUrl, e.frameOffset);
			if (image && image.complete) {
				ctx.drawImage(image,
					offset*multiplier,
					-image.height/2*multiplier,
					image.width * multiplier,
					image.height * multiplier);
				offset += image.width;
			}
		}
		ctx.restore();

		e.progress += 0.0008;
		if (e.progress > 1) {
			toiletEmotesArray.splice(index, 1);
		}
	}
	spinningEmotes_rotation += 0.005*t;
	ctx.globalAlpha = 1;
}

const risingEmotes = (ctx, canvas, r, t, first) => {
	if (first) {
		ctx.fillStyle = "#000";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
	}

	ctx.clearRect(0, 0, canvas.width, canvas.height)

	for (let index = toiletEmotesArray.length - 1; index >= 0; index--) {
		const e = toiletEmotesArray[index];

		const posX = canvas.width * e.random;
		const posY = canvas.height - e.progress*(canvas.height+50);
		let offset = 0;
		for (let i = 0; i < e.emotes.length; i++) {
			const emoteImageUrl = e.emotes[i];
			
			const image = getFrame(emoteImageUrl, e.frameOffset);
			if (image && image.complete) {
				ctx.drawImage(image,
					posX+offset,
					posY,
					image.width,
					image.height);
				offset += image.width;
			}
		}

		e.progress += 0.0025;
		if (e.progress > 1) {
			toiletEmotesArray.splice(index, 1);
		}
	}
}

let toiletEmotesArray = new Array();
const toiletEmotes = (ctx, canvas, r, t, first) => {

	ctx.clearRect(0, 0, canvas.width, canvas.height)

	for (let index = toiletEmotesArray.length - 1; index >= 0; index--) {
		const e = toiletEmotesArray[index];

		ctx.save();
		ctx.translate(canvas.width / 2, canvas.height / 2);
		ctx.rotate(Math.PI * 2 * e.random + Math.PI * e.progress * 0.5);

		const pos = (canvas.width / 2 + 20) - ((canvas.width / 2) * e.progress);
		const emoteScale = e.progress * e.progress * e.progress * e.progress;
		/*{
			progress: 0,
			random: Math.random(),
			r2: Math.random(),
			emotes: output,
		}*/
		let offset = 0;
		const mult = (1 - emoteScale);
		for (let i = 0; i < e.emotes.length; i++) {
			const emoteImageUrl = e.emotes[i];
			
			const image = getFrame(emoteImageUrl, e.frameOffset);
			if (image && image.complete) {
				ctx.drawImage(image,
					pos+offset*mult,
					pos,
					image.width * mult,
					image.height * mult);
				offset += image.width;
			}
		}
		ctx.restore();

		e.progress += 0.0025;
		if (e.progress > 1) {
			toiletEmotesArray.splice(index, 1);
		}
	}
}

const functionArray = [
	spinningEmotes,
	risingEmotes,
	toiletEmotes,
];

let functionSelector = 0;
let lastFrame = 0;
module.exports = function (ctx, canvas, r, t, first) {
	lastFrame = Date.now();
	if (first) {
		toiletEmotesArray = new Array(0);

		for (const key in emotes) {
			if (emotes.hasOwnProperty(key)) {
				emotes[key].count = 0;
			}
		}

		functionSelector++;
		while (functionSelector >= functionArray.length) functionSelector -= functionArray.length;
	}

	functionArray[functionSelector](ctx, canvas, r, t, first);
}

if (window.devEnvironment) {
	const randomEmoteSelection = [
		'admDailyQuest PepePls',
		'FeelsAmazingMan Clap',
		'admDailyQuest Clap',
	];
	setInterval(() => {
		formatEmotes(randomEmoteSelection[Math.floor(Math.random() * randomEmoteSelection.length)], []);
	}, 400);
}